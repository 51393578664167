// src/App.js
import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ProjectList from './pages/ProjectList';
import ThreateningText from './pages/ThreateningText'; // updated path

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/projects" exact component={ProjectList} />
          <Route path="/threatening-text" exact component={ThreateningText} />
          <Redirect from="/" to="/projects" />
          {/* Add more routes as needed */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
