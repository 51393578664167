// src/pages/ProjectList.js
import React from 'react';
import { Link } from 'react-router-dom';

function ProjectList() {
  const projects = [
    { path: '/threatening-text', name: 'Threatening Text Detection Model' },
    {
      path: 'https://imagescanai.s3.ap-southeast-2.amazonaws.com/index.html',
      name: 'AI Image Scanner',
      external: true,
    },
    // Add more projects as needed
  ];

  return (
    <div>
      <h1>Projects</h1>
      <ul>
        {projects.map((project) => (
          <li key={project.name}>
            {project.external ? (
              <a href={project.path} target="_blank" rel="noopener noreferrer">
                {project.name}
              </a>
            ) : (
              <Link to={project.path}>{project.name}</Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProjectList;
