// src/pages/ThreateningText.js
import React from 'react';

const ThreateningText = () => {
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', padding: '20px', boxSizing: 'border-box' }}>
            <h1>Project Overview: Threat Detection Model</h1>
            <p>
                This project develops a machine learning model to identify threats in text data, specifically comments. 
                The process encompasses data preparation, model training, hyperparameter tuning, and deployment, resulting in a 
                robust system that can effectively classify comments based on their threat level.
            </p>

            <h3>Key Steps:</h3>
            <ul>
                <li><strong>Data Preparation:</strong> Load and sample text data to ensure a balanced representation of threats.</li>
                <li><strong>Model Training:</strong> Utilize a Random Forest Classifier with hyperparameter tuning to enhance performance.</li>
                <li><strong>Evaluation:</strong> Assess the model using metrics like accuracy, precision, and recall to ensure effectiveness.</li>
            </ul>

            <h3>Next Steps:</h3>
            <p>
                Further hyperparameter tuning and model exploration could improve performance, while incorporating advanced 
                NLP techniques and larger datasets may enhance detection accuracy.
            </p>

            <a
                href="https://colab.research.google.com/drive/16R2r91xPen_UnfiZWK8qnJ0QTP7m3OJH"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: '24px', textDecoration: 'underline', marginTop: '20px' }}
            >
                Open Threatening Text Notebook
            </a>

        </div>
    );
};

export default ThreateningText;
